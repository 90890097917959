import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography @color='textSecondary' @tag='span'>\n  {{this.createdOnDate}}\n</AkTypography>\n\n{{#if @namespace.requestedBy}}\n  <AkTypography @color='textSecondary' @tag='span'>\n    {{t 'by'}}\n  </AkTypography>\n\n  <AkTypography @tag='span'>\n    {{@namespace.requestedBy.username}}\n  </AkTypography>\n{{/if}}", {"contents":"<AkTypography @color='textSecondary' @tag='span'>\n  {{this.createdOnDate}}\n</AkTypography>\n\n{{#if @namespace.requestedBy}}\n  <AkTypography @color='textSecondary' @tag='span'>\n    {{t 'by'}}\n  </AkTypography>\n\n  <AkTypography @tag='span'>\n    {{@namespace.requestedBy.username}}\n  </AkTypography>\n{{/if}}","moduleName":"irene/components/organization-namespace/request-status/index.hbs","parseOptions":{"srcName":"irene/components/organization-namespace/request-status/index.hbs"}});
import Component from '@glimmer/component';
import OrganizationNamespaceModel from 'irene/models/organization-namespace';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export interface OrganizationNamespaceRequestStatusSignature {
  Args: {
    namespace: OrganizationNamespaceModel;
  };
  Element: HTMLElement;
}

export default class OrganizationNamespaceComponent extends Component<OrganizationNamespaceRequestStatusSignature> {
  get createdOnDate() {
    return dayjs(this.args.namespace.createdOn).fromNow();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationNamespace::RequestStatus': typeof OrganizationNamespaceComponent;
  }
}
