import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkButton\n  data-test-attachmentDetail-btn='{{@attachment.uuid}}'\n  data-type='{{file-extension @attachment.name}}'\n  {{on 'click' (perform this.downloadAttachment)}}\n  title='{{@attachment.uuid}}_{{@attachment.name}}'\n  @tag='a'\n  @variant='outlined'\n>\n  <:default>\n    <AkTypography\n      {{style maxWidth='400px'}}\n      @noWrap={{true}}\n      @color='inherit'\n      @tag='span'\n      data-test-attachmentDetail-label\n    >\n      {{@attachment.uuid}}_{{@attachment.name}}\n    </AkTypography>\n  </:default>\n\n  <:rightIcon>\n    {{#if this.downloadAttachment.isRunning}}\n      <AkLoader @size={{16}} />\n    {{else}}\n      <AkIcon @iconName='download' data-test-attachmentDetail-downloadIcon />\n    {{/if}}\n  </:rightIcon>\n</AkButton>", {"contents":"<AkButton\n  data-test-attachmentDetail-btn='{{@attachment.uuid}}'\n  data-type='{{file-extension @attachment.name}}'\n  {{on 'click' (perform this.downloadAttachment)}}\n  title='{{@attachment.uuid}}_{{@attachment.name}}'\n  @tag='a'\n  @variant='outlined'\n>\n  <:default>\n    <AkTypography\n      {{style maxWidth='400px'}}\n      @noWrap={{true}}\n      @color='inherit'\n      @tag='span'\n      data-test-attachmentDetail-label\n    >\n      {{@attachment.uuid}}_{{@attachment.name}}\n    </AkTypography>\n  </:default>\n\n  <:rightIcon>\n    {{#if this.downloadAttachment.isRunning}}\n      <AkLoader @size={{16}} />\n    {{else}}\n      <AkIcon @iconName='download' data-test-attachmentDetail-downloadIcon />\n    {{/if}}\n  </:rightIcon>\n</AkButton>","moduleName":"irene/components/attachment-detail/index.hbs","parseOptions":{"srcName":"irene/components/attachment-detail/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ENV from 'irene/config/environment';
import AttachmentModel from 'irene/models/attachment';
import { task } from 'ember-concurrency';
import type IreneAjaxService from 'irene/services/ajax';
import type { AjaxError } from 'irene/services/ajax';

export interface AttachmentDetailSignature {
  Args: {
    attachment: AttachmentModel;
  };
}

interface DownloadResponse {
  data: {
    url: string;
  };
}

export default class AttachmentDetailComponent extends Component<AttachmentDetailSignature> {
  @service declare ajax: IreneAjaxService;
  @service('notifications') declare notify: NotificationService;
  @service('browser/window') declare window: Window;

  downloadAttachment = task({ drop: true }, async () => {
    const url = ENV.host + this.args.attachment.downloadUrl;

    try {
      const result = await this.ajax.request<DownloadResponse>(url);

      this.window.open(result.data.url);
    } catch (error) {
      if (!this.isDestroyed) {
        this.notify.error((error as AjaxError).payload.message);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AttachmentDetail: typeof AttachmentDetailComponent;
  }
}
