import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography\n  @variant='h5'\n  @color='textPrimary'\n  @gutterBottom={{true}}\n  data-test-settings-toggle-analysis-label\n>\n  {{t 'showUntestedAnalysis'}}\n</AkTypography>\n\n<AkFormControlLabel\n  @label={{t 'otherTemplates.showUntestedAnalysis'}}\n  @disabled={{this.toggleUnknownAnalysis.isRunning}}\n  as |fcl|\n>\n  <AkToggle\n    class='ml-0'\n    @checked={{this.unknownAnalysisStatus.status}}\n    @size='small'\n    @disabled={{fcl.disabled}}\n    @onClick={{this.showUnknownAnalysis}}\n    data-test-settings-toggle-analysis\n  />\n</AkFormControlLabel>", {"contents":"<AkTypography\n  @variant='h5'\n  @color='textPrimary'\n  @gutterBottom={{true}}\n  data-test-settings-toggle-analysis-label\n>\n  {{t 'showUntestedAnalysis'}}\n</AkTypography>\n\n<AkFormControlLabel\n  @label={{t 'otherTemplates.showUntestedAnalysis'}}\n  @disabled={{this.toggleUnknownAnalysis.isRunning}}\n  as |fcl|\n>\n  <AkToggle\n    class='ml-0'\n    @checked={{this.unknownAnalysisStatus.status}}\n    @size='small'\n    @disabled={{fcl.disabled}}\n    @onClick={{this.showUnknownAnalysis}}\n    data-test-settings-toggle-analysis\n  />\n</AkFormControlLabel>","moduleName":"irene/components/project-settings/analysis-settings/toggle-analysis/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/analysis-settings/toggle-analysis/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'irene/config/environment';
import ProjectModel from 'irene/models/project';
import IntlService from 'ember-intl/services/intl';
import Store from '@ember-data/store';
import UnknownAnalysisStatusModel from 'irene/models/unknown-analysis-status';
import { task } from 'ember-concurrency';
import parseError from 'irene/utils/parse-error';
import type IreneAjaxService from 'irene/services/ajax';

interface ProjectSettingsAnalysisSettingsToggleAnalysisSignature {
  Args: {
    project: ProjectModel | null;
  };
}

export default class ProjectSettingsAnalysisSettingsToggleAnalysisComponent extends Component<ProjectSettingsAnalysisSettingsToggleAnalysisSignature> {
  @service declare intl: IntlService;
  @service declare ajax: IreneAjaxService;
  @service('notifications') declare notify: NotificationService;
  @service declare store: Store;

  @tracked unknownAnalysisStatus?: UnknownAnalysisStatusModel | null = null;

  constructor(
    owner: unknown,
    args: ProjectSettingsAnalysisSettingsToggleAnalysisSignature['Args']
  ) {
    super(owner, args);

    this.fetchUnknownAnalysisStatus.perform();
  }

  fetchUnknownAnalysisStatus = task(async () => {
    const profileId = this.args.project?.activeProfileId;
    this.unknownAnalysisStatus = await this.store.queryRecord(
      'unknown-analysis-status',
      {
        id: profileId,
      }
    );
  });

  toggleUnknownAnalysis = task(async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const tSavedPreferences = this.intl.t('savedPreferences');
    const isChecked = target.checked;
    const profileId = this.args.project?.activeProfileId;
    const url = `${ENV.endpoints['profiles']}/${profileId}/${ENV.endpoints['unknownAnalysisStatus']}`;
    const data = { status: isChecked };

    try {
      await this.ajax.put(url, { data });
      this.notify.success(tSavedPreferences);

      if (!this.isDestroyed) {
        if (this.unknownAnalysisStatus) {
          this.unknownAnalysisStatus.status = isChecked;
        }
      }
    } catch (error) {
      this.notify.error(parseError(error));
    }
  });

  @action
  async showUnknownAnalysis(event: Event) {
    this.toggleUnknownAnalysis.perform(event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::AnalysisSettings::ToggleAnalysis': typeof ProjectSettingsAnalysisSettingsToggleAnalysisComponent;
  }
}
