import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='0.5' @justifyContent='center'>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip @placement='bottom' @arrow={{true}}>\n\n    <:tooltipContent>\n      <div local-class='tooltip-content'>\n        <AkTypography @color='inherit'>\n          {{this.tooltipText}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon @iconName='info' @size='small' @color='textSecondary' />\n    </:default>\n  </AkTooltip>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='0.5' @justifyContent='center'>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip @placement='bottom' @arrow={{true}}>\n\n    <:tooltipContent>\n      <div local-class='tooltip-content'>\n        <AkTypography @color='inherit'>\n          {{this.tooltipText}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon @iconName='info' @size='small' @color='textSecondary' />\n    </:default>\n  </AkTooltip>\n</AkStack>","moduleName":"irene/components/storeknox/discover/results/table/action-header/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/discover/results/table/action-header/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

import type MeService from 'irene/services/me';
import type SkDiscoverySearchResultModel from 'irene/models/sk-discovery-result';

interface StoreknoxDiscoverResultsTableActionHeaderSignature {
  Args: {
    data: SkDiscoverySearchResultModel;
  };
}

export default class StoreknoxDiscoverResultsTableActionHeaderComponent extends Component<StoreknoxDiscoverResultsTableActionHeaderSignature> {
  @service declare me: MeService;
  @service declare intl: IntlService;

  get isOwner() {
    return this.me.org?.is_owner;
  }

  get tooltipText() {
    if (this.isOwner) {
      return this.intl.t('storeknox.actionHeaderInfoOwner');
    }

    return this.intl.t('storeknox.actionHeaderInfo');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::Results::Table::ActionHeader': typeof StoreknoxDiscoverResultsTableActionHeaderComponent;
  }
}
