import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @alignItems='center'\n  local-class='empty-container'\n  data-test-storeknoxDiscover-resultsEmptyContainer\n>\n  <AkSvg::StoreknoxSearchApps\n    data-test-storeknoxDiscover-resultsEmptyIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    local-class='header'\n    data-test-storeknoxDiscover-resultsEmptyHeaderText\n  >\n    {{t 'storeknox.searchForApps'}}\n  </AkTypography>\n\n  <AkTypography\n    local-class='body-text'\n    data-test-storeknoxDiscover-resultsEmptyDescriptionText\n  >\n    {{t 'storeknox.searchForAppsDescription'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @alignItems='center'\n  local-class='empty-container'\n  data-test-storeknoxDiscover-resultsEmptyContainer\n>\n  <AkSvg::StoreknoxSearchApps\n    data-test-storeknoxDiscover-resultsEmptyIllustration\n  />\n\n  <AkTypography\n    @variant='h5'\n    @gutterBottom={{true}}\n    local-class='header'\n    data-test-storeknoxDiscover-resultsEmptyHeaderText\n  >\n    {{t 'storeknox.searchForApps'}}\n  </AkTypography>\n\n  <AkTypography\n    local-class='body-text'\n    data-test-storeknoxDiscover-resultsEmptyDescriptionText\n  >\n    {{t 'storeknox.searchForAppsDescription'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/storeknox/discover/results/empty/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/discover/results/empty/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxDiscoverResultsEmptyComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::Results::Empty': typeof StoreknoxDiscoverResultsEmptyComponent;
  }
}
