/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr } from '@ember-data/model';
import ENUMS from 'irene/enums';
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default class VulnerabilityPreferenceModel extends Model {
  @attr('number')
  declare risk: number;

  @attr('string')
  declare comment: string;

  @attr('string', { defaultValue: null })
  declare updatedBy: string | null;

  @attr('string', { defaultValue: null })
  declare updatedDate: string | null;

  @computed('risk')
  get riskOverridden() {
    if (!isEmpty(this.risk)) {
      return true;
    }
  }

  iconClass(risk: number) {
    switch (risk) {
      case ENUMS.RISK.UNKNOWN:
        return 'fa-spinner fa-spin';
      case ENUMS.RISK.NONE:
        return 'fa-check';
      case ENUMS.RISK.CRITICAL:
      case ENUMS.RISK.HIGH:
      case ENUMS.RISK.LOW:
      case ENUMS.RISK.MEDIUM:
        return 'fa-warning';
    }
  }

  @computed('risk')
  get riskIconClass() {
    return this.iconClass(this.risk);
  }

  labelClass(risk: number) {
    const cls = 'tag';

    switch (risk) {
      case ENUMS.RISK.UNKNOWN:
        return `${cls} is-progress`;
      case ENUMS.RISK.NONE:
        return `${cls} is-success`;
      case ENUMS.RISK.LOW:
        return `${cls} is-info`;
      case ENUMS.RISK.MEDIUM:
        return `${cls} is-warning`;
      case ENUMS.RISK.HIGH:
        return `${cls} is-danger`;
      case ENUMS.RISK.CRITICAL:
        return `${cls} is-critical`;
    }
  }

  @computed('risk')
  get riskLabelClass() {
    return this.labelClass(this.risk);
  }

  /**
   * Updates the model with the given payload
   *
   * @param payload The payload to update the model with
   */
  updateValues(payload: object) {
    const normalized = this.store.normalize(
      VulnerabilityPreferenceModel.modelName,
      payload
    );

    this.store.push(normalized);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'vulnerability-preference': VulnerabilityPreferenceModel;
  }
}
